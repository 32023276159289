import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';
import { Outlet } from '../models/outlet';
import { User } from '../models/user';
import { ConnectionService } from './connection.service';
import { JsonConverterService } from './json-converter.service';
import { LocalStorageService } from './local-storage.service';
import { OutletService } from './outlet.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _user: BehaviorSubject<User> = new BehaviorSubject<User>(
    null
  );
  get user(): User {
    return this._user.getValue();
  }
  set user(user: User) {
    this._user.next(user);
  }
  readonly user$ = this._user.asObservable();

  constructor(
    private http: HttpClient,
    private connectionService: ConnectionService,
    private jsonConverterService: JsonConverterService,
    private localStorageService: LocalStorageService,
    private outletService: OutletService
  ) {}

  /**
   * Check token validity by sending a post request
   * With authorization header
   * Note : auth-interceptor do the authorization part
   */
  checkToken(): Observable<{ user: User; outlet: Outlet }> {
    return this.http
      .post(`${this.connectionService.getMobileUrl()}/check-token`, {})
      .pipe(
        map((response: { data: any; token: string }) => response.data),
        map((data: any) => {
          const user =
            this.jsonConverterService.jsonConverter.deserializeObject(
              data,
              User
            );
          this.user = user;
          return user;
        }),
        concatMap((user: User) => {
          // the last null value is to make sure when user outlets do not have any value
          // instead of storing "undefined", I hard set it into "null"
          const selectedOutletId =
            this.localStorageService.selectedOutlet.id ||
            user.outlet.id ||
            null;

          return this.outletService.loadOutlet(selectedOutletId).pipe(
            map((outlet: Outlet) => {
              this.localStorageService.selectedOutlet = outlet;
              return { user, outlet };
            })
          );
        }),
        catchError((error) => {
          this.localStorageService.token = ''; // clear token when validation failed
          throw error;
        })
      );
  }

  /**
   * User authenticate through REST API and save the token into local storage
   * Local storage only works for native apps
   * @param email email
   * @param password user password
   */
  login(
    email: string,
    password: string
  ): Observable<{ user: User; outlet: Outlet }> {
    return this.http
      .post(`${this.connectionService.getMobileUrl()}/login`, {
        email,
        password,
      })
      .pipe(
        map((response: { data: any; token: string }) => {
          this.localStorageService.token = response.token;
          return response.data;
        }),
        map((data: any) => {
          const user =
            this.jsonConverterService.jsonConverter.deserializeObject(
              data,
              User
            );
          this.user = user;
          return user;
        }),
        concatMap((user: User) => {
          // the last null value is to make sure when user outlets do not have any value
          // instead of storing "undefined", I hard set it into "null"
          const selectedOutletId =
            this.localStorageService.selectedOutlet.id ||
            user.outlet.id ||
            null;

          return this.outletService.loadOutlet(selectedOutletId).pipe(
            map((outlet: Outlet) => {
              this.localStorageService.selectedOutlet = outlet;
              return { user, outlet };
            })
          );
        }),
        catchError((error) => {
          this.localStorageService.token = ''; // clear token when validation failed
          throw error;
        })
      );
  }

  /**
   * Logout user through REST API and clear token inside the local storage
   * Also disconnect echo client connection.
   *
   * The user is able to logout in offline mode
   */
  logout(): Observable<any> {
    return this.http
      .post(`${this.connectionService.getMobileUrl()}/logout`, {})
      .pipe(
        finalize(() => {
          // Whether it is success or not, always remove local data and
          // force the user re-login after logout
          // This allow the user to logout the apps in offline condition
          this.user = null;
          this.localStorageService.token = '';
        })
      );
  }

  register(user: User): Observable<User> {
    return this.http
      .post(`${this.connectionService.getMobileUrl()}/register`, {
        name: user.name,
        password: user.password,
        password_confirmation: user.passwordConfirmation,
        phone_number: user.phone,
        email: user.email,
        outlet_id: user.outletId,
      })
      .pipe(
        map((response: any) =>
          this.jsonConverterService.jsonConverter.deserializeObject(
            response.data,
            User
          )
        )
      );
  }
}
