import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';
import { StorageKey } from '../enums/storage-key.enum';
import { Outlet } from '../models/outlet';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _token = '';
  get token(): string {
    return this._token;
  }
  set token(newToken: string) {
    this._token = newToken;
    Preferences.set({
      key: StorageKey.Token,
      value: newToken,
    });
  }

  async getToken() {
    return await (
      await Preferences.get({ key: StorageKey.Token })
    ).value;
  }

  // Previous outlet is stored as a reference to remove any leftover data such as previous socket connection
  public previousOutlet = new Outlet({ id: 0 });
  // store outlet id to local storage
  private readonly _selectedOutlet = new BehaviorSubject<Outlet>(null);
  get selectedOutlet(): Outlet {
    // if getValue() return null, create a new outlet with id 0.
    // This is to prevent error when user authentication method try to access
    // id if null object.
    return this._selectedOutlet.getValue() || new Outlet({ id: 0 });
  }
  set selectedOutlet(outlet: Outlet) {
    this.previousOutlet = this.selectedOutlet;
    this._selectedOutlet.next(outlet);
    Preferences.set({
      key: StorageKey.Outlet,
      value: JSON.stringify(outlet),
    });
  }
  readonly selectedOutlet$ = this._selectedOutlet.asObservable();

  constructor() {
    this.loadToken();
    this.loadOutlet();
  }

  async loadToken() {
    const { value } = await Preferences.get({
      key: StorageKey.Token,
    });
    this._token = value;
  }

  async loadOutlet() {
    const { value } = await Preferences.get({
      key: StorageKey.Outlet,
    });
    this.selectedOutlet = JSON.parse(value);
  }
}
