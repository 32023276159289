import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorMessage } from '../models/error-message';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          if (environment.debug) {
            console.error('An error occurred:', error.error.message);
          }
          return throwError({
            message: 'Something went wrong, please try again.',
            errors: [],
          } as ErrorMessage);
        }

        // To catch if the IP / domain is wrong or is not online
        if (error.error instanceof ProgressEvent) {
          if (environment.debug) {
            console.error('An error occurred:', error.message);
          }
          return throwError({
            message: 'Something went wrong, please try again.',
            errors: [],
          } as ErrorMessage);
        }

        // Backend return error
        if (environment.debug) {
          console.error(
            `Server returned code ${error.status}, ` +
              `body is: ${error.error.message}`
          );
        }
        return throwError({
          message: `${error.error.message}`,
          errors: error.error.errors,
        } as ErrorMessage);
      })
    );
  }
}
