import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Outlet')
export class Outlet {
  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('address_name', String)
  addressName: string = undefined;

  @JsonProperty('address_note', String)
  addressNote: string = undefined;

  @JsonProperty('longitude', Number)
  longitude: number = undefined;

  @JsonProperty('latitude', Number)
  latitude: number = undefined;
  // @JsonProperty('code', String)
  // code: string = undefined;

  // @JsonProperty('settings', [Setting], true)
  // settings: Setting[] = undefined;

  constructor(outlet: Partial<Outlet> = {}) {
    Object.assign(this, outlet);
  }

  // /**
  //  * Get setting key value pair base on SettingKeyEnum
  //  * If not found, value will be empty string
  //  * @param settingKeyEnum setting key
  //  */
  // getSetting(settingKeyEnum: SettingKeyEnum) {
  //   return (
  //     this.settings.find(
  //       (setting: Setting) => setting.key === settingKeyEnum
  //     ) || { key: settingKeyEnum, value: '' }
  //   );
  // }

  // /**
  //  * Set setting key value pair base on SettingKeyEnum
  //  * If not found, value will be empty string
  //  * @param settingKeyEnum setting key
  //  * @param value value key
  //  */
  // setSetting(settingKeyEnum: SettingKeyEnum, value: any) {
  //   const setting = this.settings.find(
  //     (setting: Setting) => setting.key === settingKeyEnum
  //   );
  //   if (setting) {
  //     setting.value = value;
  //   }
  // }
}
