import { Injectable } from '@angular/core';
import {
  environment,
  SERVER_IP,
  SOCKET_IP,
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private readonly API_ENDPOINT = '/api';
  private readonly MOBILE_ENDPOINT = '/mobile/api';

  constructor() {}

  /**
   * Get system url base on the environment
   * @returns   e.g. http://example.com/cashier/api
   */
  getSystemUrl() {
    let url = '';

    url += environment.ssl ? 'https://' : 'http://';
    url += SERVER_IP;
    url += this.API_ENDPOINT;

    return url;
  }

  /**
   * Get tenant socket url base on the environment
   * If tenant is not selected, throw Error
   */
  getTenantSocketUrl() {
    let url = '';

    url += environment.ssl ? 'https://' : 'http://';
    url += SOCKET_IP;

    return url;
  }

  getMobileUrl() {
    let url = '';

    url += environment.ssl ? 'https://' : 'http://';
    url += SERVER_IP;
    url += this.MOBILE_ENDPOINT;

    return url;
  }
}
