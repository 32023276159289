import { JsonObject, JsonProperty } from 'json2typescript';
import { Outlet } from './outlet';

@JsonObject('User')
export class User {
  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('email', String, true)
  email: string = undefined;

  @JsonProperty('role', String, true)
  role: string = undefined;

  @JsonProperty('password', String, true)
  password: string = undefined;

  @JsonProperty('password_confirmation', String, true)
  passwordConfirmation: string = undefined;

  @JsonProperty('phone_number', String, true)
  phone: string = undefined;

  @JsonProperty('member_id', String, true)
  memberId: string = undefined;

  @JsonProperty('outlet_id', Number, true)
  outletId: number = undefined;

  @JsonProperty('outlet', Outlet, true)
  outlet: Outlet = undefined;

  constructor(user: Partial<User> = {}) {
    Object.assign(this, user);
  }
}
