import { Injectable } from '@angular/core';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';

@Injectable({
  providedIn: 'root',
})
export class JsonConverterService {
  jsonConverter: JsonConvert;

  constructor() {
    this.jsonConverter = new JsonConvert();
    this.jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }
}
