import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { FcmService } from './services/fcm.service';
import { LocalStorageService } from './services/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  lastTimeBackPress = 0;
  readonly TIME_PERIOD_TO_EXIT = 2000;
  constructor(
    private platform: Platform,
    private localStorageService: LocalStorageService,
    private navController: NavController,
    private fcmService: FcmService,
    private zone: NgZone,
    private router: Router,
    private toastController: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await this.localStorageService.getToken();
      if (this.localStorageService.token !== '') {
        this.router.navigateByUrl('/loading', { replaceUrl: true });
      } else {
        this.router.navigateByUrl('/login', { replaceUrl: true });
      }

      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(async () => {
          const domain = 'https://icemateoca.com/';
          console.log(domain);
          const pathArray = event.url.split(domain);
          // The pathArray is now like ['https://devdactic.com', '/details/42']

          console.log(pathArray);

          // Get the last element with pop()
          const appPath = pathArray.pop();
          console.log(appPath);
          if (appPath) {
            this.router.navigateByUrl(appPath);
          }
        });
      });

      // Trigger the push setup
      this.fcmService.initPush();

      App.addListener('backButton', async (data) => {
        if (data.canGoBack) {
          window.history.back();
          if (
            new Date().getTime() - this.lastTimeBackPress <
            this.TIME_PERIOD_TO_EXIT
          ) {
            App.exitApp();
          } else {
            const toast = await this.toastController.create({
              message: 'Press once again to exit',
              duration: this.TIME_PERIOD_TO_EXIT,
            });
            toast.present();
            this.lastTimeBackPress = new Date().getTime();
          }
        } else {
          // Maybe show alert before closing app?
          App.exitApp();
        }
      });
    });
  }
}
