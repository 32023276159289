import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Outlet } from '../models/outlet';
import { ConnectionService } from './connection.service';
import { JsonConverterService } from './json-converter.service';

@Injectable({
  providedIn: 'root',
})
export class OutletService {
  constructor(
    private http: HttpClient,
    private connectionService: ConnectionService,
    private jsonConverterService: JsonConverterService
  ) {}

  loadOutlets(searchTerm: string = ''): Observable<Outlet[]> {
    return this.http
      .get(`${this.connectionService.getMobileUrl()}/outlets`)
      .pipe(
        map((response: any) => {
          const outlets = response.data.map((outlet: any) =>
            this.jsonConverterService.jsonConverter.deserializeObject(
              outlet,
              Outlet
            )
          );

          return outlets;
        })
      );
  }

  loadOutlet(outletId): Observable<Outlet> {
    return this.http
      .get(`${this.connectionService.getMobileUrl()}/outlets/${outletId}`)
      .pipe(
        map((response: any) =>
          this.jsonConverterService.jsonConverter.deserializeObject(
            response.data,
            Outlet
          )
        )
      );
  }
}
