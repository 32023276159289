import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class OutletInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.localStorageService.selectedOutlet === null) {
      return next.handle(req);
    }

    const newRequest = req.clone({
      headers: req.headers.set(
        'Outlet-Id',
        this.localStorageService.selectedOutlet.id.toString()
      ),
    });
    return next.handle(newRequest);
  }
}
